<template>
  <div>
    <div style="font-size: 28px;">{{ $t('tools.a12') }}</div>
    <!-- search -->
    <div v-if="quanxian == 'Config' || quanxian == 'Admin'" class="flex" style=" margin: 30px 0;">
      <div style="width: 94%;" class="input-search">
        <div class="el-date-input" style="z-index: 100;right: 80px;opacity:0;">
          <el-select v-model="callType" @change="getList" style="width: 124px;" :placeholder="$t('common.a9')">
            <el-option
                v-for="(item,idx) in this.$t('param.a37')"
                :key="item"
                :label="item"
                :value="idx">
            </el-option>
          </el-select>
        </div>
        <div class="el-date-input"
             style="right: 80px;height: 40px;line-height: 40px;color: #9FA3AF;display: flex;font-size: 21px;background-color: #fff;">
          <div style="width: 1px;height: 27px;margin: 7px 10px 0 0;background: #9FA3AF;margin-right: 10px;"></div>
          <div style="margin-right: 10px">{{ callType > -1 ? $t('param.a37')[callType] : $t('tools.a14') }}<i
              class="el-icon-caret-bottom"></i></div>
        </div>
        <div class="el-date-input" style="z-index: 100;opacity:0;">
          <el-date-picker
              style="width: 82px;"
              @change="getList"
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="el-date-input"
             style="height: 40px;line-height: 40px;color: #9FA3AF;display: flex;font-size: 21px;background-color: #fff;">
          <div style="width: 1px;height: 27px;margin: 7px 10px 0 0;background: #9FA3AF;margin-right: 10px;"></div>
          <div style="margin-right: 10px">{{ $t('param.a9') }}<i class="el-icon-caret-bottom"></i></div>
        </div>
        <input v-model="filename" type="text" class="search" :placeholder="$t('tools.a13')">
      </div>
      <div class="search-btn" @click="getList"><i class="el-icon-search"></i></div>
    </div>
    <div v-else style="margin-top: 30px;"></div>
    <!-- button -->
    <div class="tool-btn">
      <div>
        <div style="display: inline-block;min-width: 180px;margin-right: 30px;">{{ $t('tools.a10') }}:
          {{ imeiSearch.profileName || imeiInfo.profileName }}
        </div>
        <div style="display: inline-block;">{{ $t('tools.a11') }}：
          {{ imeiSearch.profileVersion || imeiInfo.profileVersion }}
        </div>
      </div>
      <div style="width: 100px;">
        <el-tag
            closable
            type="info"
            effect="plain"
            size="medium"
            @close="closeDate"
            @click="closeDate"
            v-if="date.length"
        >
          {{ dateToString }}
        </el-tag>
      </div>
      <el-upload
          style="width: 136px;"
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :disabled="!imeiInfo.imei"
          :http-request="upload">
        <!-- 权限控制 v-if="quanxian == 'Config' || quanxian == 'Admin'" -->
        <el-button v-if="quanxian == 'Admin'" type="primary" @click="checkImport">{{ $t('tools.a15') }}</el-button>
      </el-upload>
    </div>

    <el-table
        :data="tableData"
        class="table"
        border
        height="450"
        style="width: 100%;">
      <el-table-column
          type="index"
          width="60">
      </el-table-column>
      <el-table-column
          prop="number" sortable
          :label="$t('param.a27')">
      </el-table-column>
      <el-table-column
          prop="imei" sortable
          label="IMEI">
      </el-table-column>
      <el-table-column
          prop="date" sortable
          :formatter="_formatDate"
          width="170"
          :label="$t('tools.a16')">
      </el-table-column>
      <el-table-column
          prop="type" sortable
          width="180"
          :label="$t('tools.a17')">
      </el-table-column>
      <el-table-column
          prop="import_date" sortable
          :formatter="_formatDate"
          width="160"
          :label="$t('tools.a18')">
      </el-table-column>
      <el-table-column
          prop="lang" sortable
          width="130"
          align="center"
          :label="$t('tools.a19')">
      </el-table-column>
    </el-table>
    <div style="text-align: center;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          background
          layout="prev, pager, next"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/utils/http';
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      filename: '',
      date: [],
      dateToString: '',
      callType: '0',
      imeiInfo: {},
      imeiSearch: {},
      fileUrl: '',
      tableData: [],
      quanxian: ''
    }
  },
  created() {
    let imeiInfo = sessionStorage.getItem('imeiInfo');
    if (imeiInfo) {
      this.imeiInfo = JSON.parse(imeiInfo)
    }

    let user = sessionStorage.getItem('user');
    if (user) this.quanxian = JSON.parse(user).quanxian;
    this.getList();
  },

  methods: {
    getList(e) {
      let params = {
        page: this.pageNum,
        page_size: this.pageSize,
        uid: sessionStorage.getItem('id'),
      }
      if (this.filename || this.imeiInfo.imei) {
        params.imei = this.filename || this.imeiInfo.imei;
      }
      if (this.callType && this.callType != '0') {
        params.type = this.callType;
      }
      if (this.date.length) {
        params.stime = Number((this.date[0].getTime() / 1000).toFixed(0));
        params.etime = Number(((this.date[1].getTime() + 86399999) / 1000).toFixed(0));

        this.dateToString = new Date(this.date[0].getTime() + 3600000 * 8).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').substr(0, 10) + " ~ " +
            new Date(this.date[1].getTime() + 3600000 * 8).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').substr(0, 10)
      }
      this.postRequest('index/calllog_list', params, res => {
        this.tableData = res.data.info;
        this.total = res.data.count;
        let info = res.data.pro;
        if (info && info.length) {
          this.imeiSearch.imei = info.imei;
          this.imeiSearch.version = info.phone_version;
          this.imeiSearch.profileVersion = info.profile_version;
          this.imeiSearch.profileName = info.profile_name;
        }
      }, true)
    },
    upload(file) {
      let formdata = new FormData();
      formdata.append('file', file.file)
      this.postFile('index/shangchan', formdata, (res) => {
        this.fileUrl = res.data;
        this.postRequest('index/read_calllog', {
          uid: sessionStorage.getItem('id'),
          imei: this.imeiInfo.imei,
          url: res.data
        }, res => {
          this.filename = '';
          this.imeiSearch = {};
          this.getList();
        }, true)
      })
      // this.postRequest('index/read_phone_version', {
      //   uid: sessionStorage.getItem('id'),
      //   phone_version: this.imei.version,
      //   imei: this.imei.imei,
      //   profile_name: this.imei.profileName,
      //   profile_version: this.imei.profileVersion
      // }, (res) => {},true)
    },
    pushCreate() {
      this.$router.push('/BasicParameters')
    },
    checkImport() {
      if (!this.imeiInfo.imei) {
        this.$message({
          type: 'warning',
          message: this.$t('message.a8')
        })
      }
    },

    closeDate() {
      this.date = [];
      this.dateToString = '';
      this.getList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    _formatDate(row, col, value, index) {
      return formatDate(value);
    },
  }
}
</script>

<style scoped>
.search {
  width: 100%;
  height: 46px;
  border: 3px solid #5D80F5;
  border-radius: 1px;
  font-size: 18px;
  text-indent: 20px;
  box-sizing: border-box;

}

.search:focus-visible {
  outline: none;
}

.search-btn {
  width: 6%;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  color: #C3D0FB;
  background: #5D80F5;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.tool-btn {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
}

.tool-btn > div {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  line-height: 44px;
}

.table {
  margin: 30px 0;
}

.input-search > .el-date-input {
  position: absolute;
  right: 0;
  top: 3px;
}

.input-search {
  position: relative;
  width: 94%;
}
</style>
